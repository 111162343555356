html {
    font-size: 100%; /*16px*/
}

body {
    margin: 0;
    background: #233329;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.75;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin-bottom: 1rem;
}

h1, h2, h3, h4, h5 {
    margin: 3rem 0 1.38rem;
    font-family: 'Abril Fatface', cursive;
    font-weight: 400;
    line-height: 1.3;
    color: #b7ad99;
}

h1 {
    margin-top: 0;
    font-size: 3.052rem;
}

h2 {
    font-size: 2.441rem;
}

h3 {
    font-size: 1.953rem;
    font-family: 'Poppins', sans-serif;
    color: #fff;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.25rem;
}

a {
    color: #96c5f7;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
